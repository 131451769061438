// src/components/Footer/Footer.tsx

import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <span>© 2023-2025 Wisanna, All rights reserved</span>
                <span className="footer-links">
                    <a href="https://wisanna.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> |
                    <a href="https://wisanna.com/cookies" target="_blank" rel="noopener noreferrer">Cookie Notice</a> |
                    <a href="https://wisanna.com/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                </span>
            </div>
        </footer>
    );
};

export default Footer;